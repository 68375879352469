import React from "react"
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import { Page } from "../components/core";
 
export default class extends React.Component {
  constructor(props) {
    super(props);
 
    let content =  this.props.data.story ? JSON.parse(this.props.data.story.content) : {}
    // we need to join the story articles with the full posts information
    let articles = this.props.data.articles.edges.map(n => n.node)
    let articleList = content.body.find(c => c.component === 'article_list')
    if(articleList) {
        let index = content.body.indexOf(articleList)
        let joinedArticles = articleList.articles.map(uuid => {
            let fullPost = articles.find(p => p.uuid === uuid)
            let content = fullPost ? JSON.parse(fullPost.content) : ""
            return Object.assign({}, fullPost, { content })
        })
        content.body[index].articles = joinedArticles  
    }   
    this.state = {
        story: {
          content
        }
    };
  }
 
  async getInitialStory() {
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.data.story.full_slug}`,{
        "resolve_relations": "article_list.articles"
      })
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory()
    if(story.content) this.setState({ story })
    setTimeout(() => StoryblokService.initEditor(this), 200)
  }
 
  render() {
    return (
      <Page blok={this.state.story.content} />
    )
  }
}
 
export const query = graphql`
  query {
    story: storyblokEntry(full_slug: { eq: "blog" }) {
      name
      content
      full_slug
      uuid
    }
    articles: allStoryblokEntry(filter: {full_slug: {regex: "/articles/(.)+/"}}) {
      edges {
        node {
          full_slug
          uuid
          name
          content
          tag_list
        }
      }
    }
  }
`